.project-card {
  .project-name {
    background: #999;
  }

  .project-image {
    padding: 20px;
    background: #aaa;
  }

  &:hover {
    background-color: red;
  }
}
