// Bootstrap 4 breakpoints & gutter
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

$grid-gutter-width: 30px !default;

// number of cards per line for each breakpoint
$cards-per-line: (
  xs: 1,
  sm: 1,
  md: 1,
  lg: 2,
  xl: 2
);

@each $name, $breakpoint in $grid-breakpoints {
  @media (min-width: $breakpoint) {
    .card-columns.responsive {
      column-count: map-get($cards-per-line, $name);
    }
  }
}
